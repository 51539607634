<template>
	<div class="index-container">
		<el-row :gutter="20">
			<!-- 资源概况 -->
			<el-col :span="24">
				<el-card>
					<h4 class="font-lg font-weight-normal m-0 mb-3 text-black">
						教学申请[{{year || ''}}]
						<div class="d-inline-block ml-1" style="width: 200px;">
							<el-select placeholder="请选择学年" size="medium" class="w-100" v-model="s_id" @change="changeSemester">
								<el-option :value="item.id" :label="item.year + item.tag" v-for="(item,index) in semester" :key="item.id"/>
							</el-select>
						</div>
					</h4>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#3040F4,#4183F4);">
									示范课
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_sfk')">
										示范课申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.sfk_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#F08E34,#FEA24D);">
									课题研究
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_ktyj')">
										课题研究申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.ktyj_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#04ABE5,#50CAE7);">
									教师培训
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_jspx')">
										教师培训申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.jspx_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#02C98B,#11DCB5);">
									论文发表
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_lwfb')">
										论文发表申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.lwfb_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#00FF00,#55FE00);">
									教学获奖
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_jxhj')">
										教学获奖申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.jxhj_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#0000FF,#1A1AF7);">
									奥赛指导
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_aszd')">
										奥赛指导申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.aszd_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#5700FF,#935FFA);">
									非奥赛指导
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_faszd')">
										非奥赛指导申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.faszd_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#AB00FE,#BF4DF5);">
									实践活动
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_shsjhd')">
										社会实践活动申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.shsjhd_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#FF03FF,#F552F5);">
									研究性学习
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_yjxx')">
										研究性学习申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.yjxx_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#FD00AC,#FA6ACE);">
									社团指导
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_stzd')">
										社团指导申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.stzd_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#FF0057,#F26293);">
									竞赛成绩
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;"  @click="goto('user_jscj')">
										竞赛成绩申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.jscj_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="bg-main rounded position-relative mb-2 p-1 bx" style="height:100px;">
								<div class="position-absolute left-0 top-0 rounded-tab text-white font-s flex-all px-1 bx" style="height: 25px;background:linear-gradient(to top right,#FD5501,#F5A074);">
									获奖荣誉
								</div>
								<div class="w-100 h-100 flex flex-column justify-center pt-2 bx">
									<div class="w-100 flex align-center text-white font-s border-bottom font py-1 bx" style="cursor: pointer;" @click="goto('user_hjmc')">
										获奖荣誉申请<i class="el-icon-arrow-right ml-auto"></i>
									</div>
									<div class="w-100 flex align-center text-white font-s py-1 bx">
										当前得分：<span class="font-weight-bold font">{{apply_score.hjmc_score || 0}}</span>分
									</div>
								</div>
							</div>
						</el-col>
				</el-card>
			</el-col>
		</el-row>
	
		<!-- 项目汇总--状态统计 -->
		<el-card class="mt-3">
			<h4 class="font-lg font-weight-normal m-0 mb-3 text-black">考核汇总</h4>
			<el-row :gutter="20" type="flex">
				<el-col :span="24">
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.dkl_score || 0}}</p>
						<span class=" d-inline-block opacity-60">代课量</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.jxcg_score || 0}}</p>
						<span class="d-inline-block opacity-60">教学常规</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.jtbk_score || 0}}</p>
						<span class="d-inline-block opacity-60">集体备课</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.jyhd_score || 0}}</p>
						<span class="d-inline-block opacity-60">教研活动</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.hdzd_score || 0}}</p>
						<span class="d-inline-block opacity-60">活动指导</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2" v-if="score.ungk_score == 0">
						<p class="m-0 fs_24 mb-2">{{score.jxcj_score || 0}}</p>
						<span class="d-inline-block opacity-60">教学成绩</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2" v-else>
						<p class="m-0 fs_24 mb-2">{{score.jscj_score || 0}}</p>
						<span class="d-inline-block opacity-60">竞赛成绩</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.jsry_score || 0}}</p>
						<span class="d-inline-block opacity-60">教师荣誉</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.jzjx_score || 0}}</p>
						<span class="d-inline-block opacity-60">兼职绩效</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.dwjk_score || 0}}</p>
						<span class="d-inline-block opacity-60">对外监考</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.jlcf_score || 0}}</p>
						<span class="d-inline-block opacity-60">纪律处罚</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.ungk_score || 0}}</p>
						<span class="d-inline-block opacity-60">非高考科目</span>
					</el-col>
					<el-col :span="4" class="text-center status-item mb-2">
						<p class="m-0 fs_24 mb-2">{{score.total || 0}}</p>
						<span class="d-inline-block opacity-60">总分</span>
					</el-col>
				</el-col>

			</el-row>
		</el-card>
	</div>
</template>

<script>
import * as echarts from "echarts"
	export default {
		name: 'Index',
		data () {
			return {
                score:{},
				apply_score:{},
				semester:{},
				year:'',
				s_id:'',
			}
		},
		mounted(){
			this.getsemester()
		},
		methods:{
			changeSemester(e){
				this.s_id = e
				this.getScoreDetail()
				this.getApplyScore()
			},
			goto(url){
				this.$router.push({
				  path:"/"+url,
				  query: {}
				})
			},
			getsemester(){
				this.axios.get('/manage/semester/all').then(res=>{
					if(res.status){
						this.semester = res.data
						if(res.data.length > 0){
							this.s_id = res.data[0].id
							this.year = res.data[0].year +'-'+ res.data[0].tag
							this.getScoreDetail()
							this.getApplyScore()
						}
					}
				})
			},
            getScoreDetail(){
			   this.axios.get('/manage/teacher/teacher_score',{
				   params:{
					   s_id:this.s_id
				   }
			   }).then(res=>{
				   if(res.status){
					   	this.score = res.data
				   }
			   })
            },
			getApplyScore(){
				this.axios.get('/manage/teacher/apply_score',{
					params:{
						s_id:this.s_id
					}
				}).then(res=>{
					this.apply_score = res.data
				})
			},
		},
}
</script>

<style>
</style>